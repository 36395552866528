.m-l-s {
    margin-left: 5px;
}

.m-l-m {
    margin-left: 15px;
}

.m-l-l {
    margin-left: 25px;
}

.m-r-s {
    margin-right: 5px;
}

.m-r-m {
    margin-right: 15px;
}

.m-r-l {
    margin-right: 25px;
}

.m-b-s {
    margin-bottom: 5px;
}

.m-b-m {
    margin-bottom: 15px;
}

.m-b-l {
    margin-bottom: 25px;
}

.p-l-s {
    padding-left: 5px;
}

.p-l-m {
    padding-left: 15px;
}

.p-l-l {
    padding-left: 25px;
}

.p-r-s {
    padding-right: 5px;
}

.p-r-m {
    padding-right: 15px;
}

.p-r-l {
    padding-right: 25px;
}

.fs-s {
    font-size: 75%;
}

.fs-l {
    font-size: 125%;
}

@media print {
    a[href]:after {
        content: none !important;
    }
}