a.logo {
    font-family: @font-family-serif;
    font-size: @font-size-h0;
    text-decoration: none;
    text-align: center;
    width: 100%;
}

.logo .slogan {
    font-family: @font-family-sans-serif;
    display: block;
    font-size: @font-size-h4;
    font-style: italic;
    color: #555555;
    line-height: 2.2;
}

.logo-left {
    color: @brand-danger;
}

.logo-right {
    color: black;
}

.logo-right-inverse {
    color: white;
}
