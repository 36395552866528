.card {
    position: relative;
    cursor: pointer;
    transform-style: preserve-3d;
}

.card .card-caption {
    font-size: @font-size-h2;
    color: black;
    padding: 15px;
    transform: translateZ(50px) translateY(-50%) translateX(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 50px 0 rgba(51, 51, 51, 0.3);
}

.card .card-caption.inverted {
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal.xlarge .bg-carousel {
    height: calc(100% - 53px);
}

@media (max-width: @screen-xs-max) {
    .modal.xlarge .modal-dialog {
        width: 100vw;
        height: calc(67vmax + 53px);
        margin: 0;
    }
}

@media (min-width: @screen-sm-min) {
    .modal.xlarge .modal-dialog {
        width: 100vw;
        height: calc(67vw + 53px);
        margin: 0;
    }
}

@media (min-width: @screen-md-min) {
    .modal.xlarge .modal-dialog {
        width: 90vw;
        height: calc(60vw + 53px);
        margin: 30px auto;
    }
}

@media (min-width: @screen-lg-min) {
    .modal.xlarge .modal-dialog {
        width: 60vw;
        height: calc(40vw + 53px);
        margin: 30px auto;
    }
}

.modal.xlarge .modal-content,
.modal.xlarge .modal-body,
.modal.xlarge .bootbox-body {
    height: 100%;
}