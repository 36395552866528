@import "../../node_modules/bootstrap/less/bootstrap";
@import "../../node_modules/bootswatch/simplex/variables";
@import "../../node_modules/bootswatch/simplex/bootswatch";
@import (inline) "../../node_modules/photoswipe/dist/photoswipe.css";
@import (inline) "../../node_modules/photoswipe/dist/default-skin/default-skin.css";
@import (inline) "../../node_modules/vplayer/style.css";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700");
@import "variables";
@import "components";
@import "utilities";

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.body-main {
    flex: 1;
}

.body-main > .container,
.body-main > .container-fluid {
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

nav.navbar {
    margin-bottom: 0;
    z-index: 100;
}

.hero-block {
    min-height: 300px;
    padding: 0 !important;
}

@media (max-width: @screen-xs-max) {
    .hero-block {
        height: 50vw;
    }

    .hero-block.three-two-ratio {
        height: 66vw;
    }
}

@media (min-width: @screen-sm-min) {
    .hero-block {
        height: 375px;
    }
}

@media (min-width: @screen-md-min) {
    .hero-block {
        height: 485px;
    }
}

@media (min-width: @screen-lg-min) {
    .hero-block {
        height: 585px;
    }
}

footer {
    background-color: #333333;
    color: #9E9E9E;
    padding-top: 10px;
    text-align: center;
}

.title-block {
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 0;
    margin-top: 15px;
}

.title-block-hr {
    border: 0;
    height: 1px;
    margin-top: 0;
    background-image: linear-gradient(to left, #FFFFFF, #999999);
    margin-bottom: 15px;
}

.hr-caption {
    text-align: center;
}

.hr-caption span {
    font-family: 'Playfair Display', serif;
    background-color: #FFFFFF;
    padding: 15px;
    font-weight: bold;
    font-style: italic;
}

.hr-caption::after {
    display: block;
    content: "";
    border: 1px solid #CCCCCC;
    margin-top: -0.85em;
}

.sub-hero-block {
    margin-top: 15px;
    width: 100%;
    height: 30vh;
    background-size: cover;
    background-position: 50% 50%;
}

.vert-menu {
    border-bottom: 1px solid #E7E7E7;
}

.vert-menu h3 {
    font-size: 1.5em;
    font-weight: bold;
    padding-bottom: 3px;
    margin-bottom: 5px;
}

label.required:after {
    content: '*';
    color: @brand-danger;
    font-weight: bold;
    font-size: @font-size-h6;
    vertical-align: super;
}

#vplayer-close-btn {
    transform: rotate(0deg);
}