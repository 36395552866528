@navbar-default-link-color: @gray-dark;
@navbar-default-link-active-color: @brand-danger;
@navbar-default-link-hover-color: @brand-danger;

.navbar-clean {
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 0;
}

.navbar-clean ul.navbar-nav > li {
    margin-left: 1px;
    margin-right: 1px;
}

.navbar-clean ul.navbar-nav > li:last-child {
    border-right-width: 0;
}

.navbar-clean ul.navbar-nav > li > a {
    color: @navbar-default-link-color;
    padding-bottom: 10px;
    margin-bottom: 4px;
}

.navbar-clean ul.navbar-nav > li > a:hover,
.navbar-clean ul.navbar-nav > li > a:focus,
.navbar-clean ul.navbar-nav > li.active > a {
    border-bottom: 2px solid #333333;
    padding-bottom: 8px;
    background-color: inherit;
    color: @navbar-default-link-active-color;
    background-image: none;
    box-shadow: none;
}

.navbar-clean ul.sub-navbar {
    list-style-type: none;
    margin: 0;
    font-size: 85%;
    text-align: center;
    padding: 0 0 5px;
}

.navbar-clean ul.sub-navbar > li {
    padding: 2px 0;
}

//.navbar-clean ul.sub-navbar > li > a:before,
//.navbar-clean ul.sub-navbar > li > a:after {
//    content: '\00a0\00a0\2013\00a0\00a0';
//}
//
//.navbar-clean ul.sub-navbar > li.active > a:before {
//    content: '\00a0\00a0\00bb\00a0\00a0';
//}
//
//.navbar-clean ul.sub-navbar > li.active > a:after {
//    content: '\00a0\00a0\00ab\00a0\00a0';
//}

.navbar-clean ul.sub-navbar > li > a {
    color: @navbar-default-link-color;
}

.navbar-clean ul.sub-navbar > li.active > a,
.navbar-clean ul.sub-navbar > li > a:focus,
.navbar-clean ul.sub-navbar > li > a:hover {
    color: @navbar-default-link-hover-color;
}

.nav-phone {
    color: @brand-danger !important;
    font-size: 1.65rem;
}

.sub-navbar .nav-phone {
    font-size: 1.5rem;
}

.nav-phone .glyphicon {
    vertical-align: -2px;
    margin-right: 3px;
}

@media (max-width: @screen-xs-max) {
    .navbar-clean ul.sub-navbar {
        text-align: left;
        text-indent: 3em;
        font-size: 100%;
    }

    .navbar-clean ul.sub-navbar > li > a:before,
    .navbar-clean ul.sub-navbar > li > a:after {
        content: '' !important;
    }

    .navbar-clean ul.navbar-nav > li > a:hover,
    .navbar-clean ul.navbar-nav > li > a:focus,
    .navbar-clean ul.navbar-nav > li.active > a {
        border-bottom-width: 0;
        font-weight: bold;
        text-decoration: none;
    }
}