ul.link-list {
  margin-top: 10px;
}

ul.link-list > li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
}

ul.link-list > li > a {
  color: #9E9E9E;
  text-decoration: none;
  text-transform: uppercase;
}

ul.link-list.lg > li > a {
  font-size: 1.1em;
}

ul.link-list.sm > li > a {
  font-size: 0.8em;
}